import React from "react";
import "../styles/Terms.css";

const TermsOfUse = () => {
  return (
    <div className="terms-container">
      <h1 className="terms-title">Acuerdo de Términos de Uso para <strong>LastPage</strong></h1>
      <p className="terms-date">Última actualización: 12/02/2025</p>
      <p>
        Bienvenido a <strong>LastPage</strong>. Este Acuerdo de Términos de Uso (“Acuerdo”) regula el uso de
        <strong> LastPage</strong>, que permite a los usuarios guardar y compartir enlaces, publicar e
        interactuar con contenido, y participar en la comunidad.
      </p>
      <p>
        Al utilizar <strong>LastPage</strong>, aceptas cumplir con estos términos. Si no estás de acuerdo, no
        utilices la aplicación.
      </p>
      
      <h2 className="terms-subtitle">1. Reglas de Conducta</h2>
      <p>Para garantizar un entorno seguro y respetuoso, los usuarios deben cumplir con las siguientes reglas:</p>
      <ul className="terms-list">
        <li><strong>No se permite contenido ofensivo o inapropiado:</strong> No puedes publicar contenido que sea violento, amenazante, difamatorio, obsceno, pornográfico, discriminatorio o ilegal.</li>
        <li><strong>No se tolera el acoso ni el abuso:</strong> No puedes acosar, amenazar o intimidar a otros usuarios.</li>
        <li><strong>Respeta los derechos de autor y la privacidad:</strong> No compartas contenido sin permiso del autor ni información privada de otras personas.</li>
        <li>No uses <strong>LastPage</strong> para spam o publicidad no autorizada.</li>
        <li>Debes ser mayor de 18 años para usar <strong>LastPage</strong>.</li>
      </ul>
      <p>Si violas estas reglas, tu cuenta puede ser suspendida o eliminada sin previo aviso.</p>
      
      <h2 className="terms-subtitle">2. Moderación y Reportes</h2>
      <p>
        Nos reservamos el derecho de eliminar contenido o restringir el acceso de cualquier usuario que viole estos términos.
      </p>
      <p>
        Si encuentras contenido ofensivo o comportamiento abusivo, puedes reportarlo dentro de la aplicación. Revisaremos los reportes y tomaremos las acciones necesarias.
      </p>
      
      <h2 className="terms-subtitle">3. Privacidad y Seguridad</h2>
      <p>
        Toda la información compartida en <strong>LastPage</strong> como “Hotlinks” puede ser vista por otros usuarios. Toda la información compartida en <strong>LastPage</strong> como “Colecciones” puede ser vista por otros usuarios según la configuración de privacidad de cada colección.
      </p>
      
      <h2 className="terms-subtitle">4. Cambios en los Términos</h2>
      <p>
        Podemos actualizar estos términos en cualquier momento. Si realizamos cambios importantes, te notificaremos dentro de la app.
      </p>
      
      <h2 className="terms-subtitle">5. Contacto</h2>
      <p>
        Si tienes preguntas o necesitas reportar una infracción, puedes contactarnos por redes sociales:
      </p>
      <p>📧 <a href="" className="terms-link">Instagram: lastpageapp</a></p>
      
      <h2 className="terms-subtitle">Glosario</h2>
      <p><strong>Hotlinks:</strong> Los Hotlinks dentro de <strong>LastPage</strong>, son todos aquellos enlaces que compartes con la comunidad.</p>
      <p><strong>Colecciones:</strong> Las Colecciones son grupos de enlaces que el usuario guarda voluntariamente dentro de la aplicación.</p>
    </div>
  );
};

export default TermsOfUse;
